<template>
  <div class="container">
    <div class="top">
      <a href="javascript:;" class="back" @click="back">返回</a>
      <h1>快报详情</h1>
    </div>
    <div class="title">{{detail.title}}</div>
    <div class="info">
      <div class="time">
        发布时间：
        <span>{{detail.createDate}}</span>
      </div>新闻来源：
      <span>{{detail.source}}</span>
    </div>
    <div class="line">
      <span>
        <img src="../../hotel/img/icon-detail.png" alt />
      </span>
    </div>
    <div class="content" v-html="detail.content"></div>
    <div class="loading" v-show="loading">
      <img src="../../hotel/img/loading.png" alt="正在加载" />正在加载
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      api: {
        detail: "/content/findContent", // 获取详情
      },
      loading: true,
      contentId: null, // 文章id
      detail: [], // 详情
      from: null // 判断来源是首页还是列表
    };
  },
  created: function () {

    this.getParams()
  },
  watch: {
    // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
    $route: "getParams",
  },
  methods: {
    // 获取文章 id
    getParams() {
      this.contentId = this.$route.query.id
      this.from = this.$route.query.from
      this.getDetail()
    },
    // 获取文章详情
    getDetail() {
      this.loading = true
      let _this = this
      this.$get({
        url: _this.api.detail,
        params: {
          contentId: this.contentId,
        }
      }).then((rsp) => {
        _this.detail = rsp
        _this.detail.createDate = _this.detail.createDate.substr(0,10)
        _this.loading = false
      });
    },
    // 顶部后退
    back() {
      this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
.container {
  padding: 50px 15px 15px 15px;
  color: #333;
  text-align: left;
  box-sizing: border-box;
}
.top{
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 10;
  box-shadow: 0 0 5px #ccc;
}
.top a{
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: url(../../hotel/img/black_back.png) no-repeat center center/auto 18px;
  font-size: 0;
}
.top h1{
  font-size: 16px;
  line-height: 50px;
}
.title {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
}
.info {
  font-size: 12px;
  color: #888;
  line-height: 20px;
}
.info .time {
  float: right;
}
.info .time em{
  font-style: normal;
}
.line {
  text-align: center;
  height: 1px;
  border-top: 1px dashed #bbb;
  margin: 10px 0;
  line-height: 0;
}
.line img {
  height: 16px;
}
.line span {
  background: #fff;
  padding: 0 10px;
  margin-top: -9px;
  display: inline-block;
}
.content {
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  padding-top: 10px;
}
.content >>> img {
  max-width: 100%;
  margin-top: 10px;
}
.loading {
  font-size: 0.4rem;
  padding: 0.2rem;
  color: #ccc;
  text-align: center;
}
.loading img {
  height: 0.4rem;
  vertical-align: middle;
  margin: -0.1rem 0.1rem 0 0;
  animation: loading 0.5s linear infinite;
}
/*数据加载动画*/
@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
</style>
